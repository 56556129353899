import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Col, Row } from 'components/Core/Grid';
import { taxonomyString, getReadingMinutes, getRichTextWordCount } from 'utils/text';
import useCheckIsSingleEmailForm from 'components/Form/utils/checkIsSingleEmailForm';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { STIcon } from 'components/Brand/Icons/STIcon';
import CallToActionCards from 'components/Sections/CallToActionCards';
import SummarySection from 'components/Sections/SummarySection';
import WebsiteImage from 'components/WebsiteImage';
import ToolboxLayout from 'layouts/toolboxLayout';
import TableOfContents from 'components/Page/BlogDetailsTemplate/BlogTableOfContents';
import useIsClient from 'hooks/useIsClient';
import StyledRichText from 'components/RichText/StyledRichText';
import Text from 'components/Core/Text';
import HeroSubscribeForm from 'components/Form/HeroSubscribeForm';
import SocialSharing from 'components/SocialSharing';
import useCoronaPosts from 'hooks/useCoronaPosts';
import parseRichTextArticle from 'utils/parseRichTextArticle';
import useBreakpointView from 'utils/useBreakpointView';
import MarketoForm from 'components/Form/MarketoForm';
import SidebarV2 from 'components/Sections/Sidebar/SidebarV2';
import TocRichText from 'components/RichText/ContentfulRichText/TableOfContents/TocRichText';
import BookmarkContext from 'utils/BookmarkContext';
import useAllBlogPosts from 'hooks/useAllBlogPosts';
import getRelatedPosts from './tools/utils/getRelatedPosts';

const coronavirus = 'coronavirus';

const ArticleDescriptors = ({ iconData, text }) =>
  text ? (
    <Col>
      <span>
        <STIcon type={iconData.type} alt={iconData.alt} style={iconData.style} />
        {text}
      </span>
    </Col>
  ) : null;

const BlogDetailsTemplate = (props) => {
  const {
    authorReference,
    updatedAt,
    contentRichText,
    featuredImage,
    fullWidth,
    id,
    inlineMarketingForm,
    publishDate,
    rawDate,
    relatedPosts,
    seo: seoContent,
    summary,
    tableOfContents: useSideBarToc,
    title,
    tags,
    sideBarCta,
  } = props.data.contentfulBlog;
  const [toc, setToc] = useState(null);
  const [body, setBody] = useState(null);
  const isSingleEmailForm = useCheckIsSingleEmailForm(inlineMarketingForm?.marketoForm);
  const isClient = useIsClient();
  const hubType = (tags || []).includes('Covid19') ? coronavirus : 'blog';
  const isMobile = useBreakpointView(['xs', 'sm', 'md']);
  const showToc = useBreakpointView(['xs', 'sm', 'md', 'lg']);
  const readingMinutes = getReadingMinutes(getRichTextWordCount(contentRichText));
  const taxonomy = seoContent?.taxonomy;
  const allCoronaPosts = useCoronaPosts();
  const allBlogPosts = useAllBlogPosts();

  const getRelatedBlogPosts = () => {
    let relatedBlogPosts;

    if (relatedPosts?.length >= 3) {
      relatedBlogPosts = relatedPosts;
    } else {
      const posts = hubType === coronavirus ? allCoronaPosts : allBlogPosts;
      const excludePosts = posts.filter((x) => x.id !== id);
      const relatedPostsByTaxonomy = getRelatedPosts(taxonomy, excludePosts);

      relatedBlogPosts = relatedPostsByTaxonomy.length >= 3 ? relatedPostsByTaxonomy : posts;
    }

    return relatedBlogPosts.slice(0, 3);
  };

  const articleDescriptorData = [
    {
      iconData: { type: 'user', alt: 'User Icon', style: { margin: '0px 8px 0px 0px' } },
      text:
        authorReference?.firstName &&
        [authorReference?.firstName, authorReference?.lastName].join(' '),
    },
    {
      iconData: { type: 'clock', alt: 'Clock Icon', style: { margin: '0px 8px -2px 0px' } },
      text: publishDate,
    },
    !isMobile && {
      iconData: { type: 'glasses', alt: 'Glasses Icon', style: { margin: '0px 8px -2px 0px' } },
      text: `${readingMinutes} Min Read`,
    },
  ];

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: hubType === coronavirus ? 'Coronavirus' : 'Blog',
        icon: null,
        url: hubType === coronavirus ? '/coronavirus' : '/blog',
      },
    ],
    absolute: true,
    theme: 'dark',
  };

  const bookmarkData = {
    subTitle: taxonomyString(taxonomy),
    image: featuredImage,
    type: 'Article',
    title,
    url: props.path,
  };

  useEffect(() => {
    const parsedBlog = parseRichTextArticle(contentRichText, useSideBarToc ? 'v2' : 'v1');
    setToc(parsedBlog.toc);
    setBody(parsedBlog.body);
  }, []);

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout
        blogProps={{ author: authorReference, updatedAt: updatedAt, publishDate: rawDate }}
        breadcrumbs={breadcrumbs}
        contentfulSeo={{
          ...seoContent,
          pageCategory: hubType === coronavirus ? 'Corona Virus' : 'Blog',
          featuredImage: seoContent?.featuredImage || { image: featuredImage },
        }}
        layout="toolbox"
        useProgressbar
      >
        <SectionLayout backgroundColor="white">
          <BlogDetails className={`${fullWidth ? 'fullWidth' : ''}`}>
            <Col>
              {taxonomy && (
                <Row className="section-blog-details-category" justify="center">
                  <Text type="overline" color="var(--titan-blue-4)">
                    {taxonomyString(taxonomy)}
                  </Text>
                </Row>
              )}

              <Row className="section-blog-details-titles" justify="center">
                <BlogDetailsTitle>{title}</BlogDetailsTitle>
              </Row>
              <BlogAuthorDate>
                <Row justify={isMobile ? 'start' : 'center'}>
                  {articleDescriptorData.map((item, index) => (
                    <ArticleDescriptors {...item} key={index} />
                  ))}
                </Row>
              </BlogAuthorDate>
              <SocialSharing placement="mobile" />
            </Col>
            <Row className="section-blog-details-contents" type="flex" justify="center">
              <Col
                className="section-blog-details--main-contents"
                sm={24}
                md={24}
                lg={fullWidth ? 20 : 18}
                xl={fullWidth ? 20 : 16}
              >
                {featuredImage && isClient && (
                  <WebsiteImage
                    loading="eager"
                    image={featuredImage}
                    alt={title}
                    width="100%"
                    marginBottom="32px"
                    className="section-blog-details--featured-image"
                  />
                )}

                <Col
                  md={24}
                  lg={fullWidth ? 20 : 22}
                  xl={fullWidth ? 20 : 22}
                  style={inlineMarketingForm ? { paddingTop: '40px' } : null}
                  className="section-blog-details--content"
                >
                  {inlineMarketingForm?.marketoForm &&
                    (isSingleEmailForm ? (
                      <HeroSubscribeForm
                        className="blog-details--email-subscribe-form"
                        data-position="EmailSubscribe"
                        formType="Email Capture"
                        inlineMarketingForm={inlineMarketingForm}
                      />
                    ) : (
                      <MarketoForm
                        inlineMarketingForm={inlineMarketingForm}
                        backgroundColor="var(--dark)"
                        titleColor="var(--white)"
                        theme="blog"
                        className="blog-details--full-form"
                        shadow
                        formType="Blog Details Form"
                        fontSize="16px"
                        notificationStyles="section-blog-details-main-form-notification"
                        notificationMessage="Thanks for subscribing!"
                        showPrivacy
                      />
                    ))}
                  {toc && !useSideBarToc && <TableOfContents content={toc} />}
                  {contentRichText &&
                    (useSideBarToc ? (
                      <TocRichText content={body} toc={toc} />
                    ) : (
                      <StyledRichText content={!toc ? contentRichText : body} />
                    ))}
                  {summary && (
                    <SummarySection
                      className="test-summary-section"
                      headline={summary.headline}
                      subHead={summary.subhead}
                      callToAction={summary.cta}
                      backgroundImage={summary.backgroundImage}
                    />
                  )}
                </Col>
              </Col>

              {!fullWidth && (
                <Col xs={24} sm={24} lg={6} xl={8} className="section-blog-details-sidebar">
                  <SocialSharing placement="blog" />
                  <SidebarV2
                    toc={useSideBarToc && !showToc && toc}
                    demo
                    {...(sideBarCta ? { customCta: sideBarCta } : { email2: true })}
                  />
                </Col>
              )}
            </Row>
          </BlogDetails>
        </SectionLayout>
        <CallToActionCards
          title="Related posts"
          backgroundColor="White"
          cardType="Image"
          bodyType="blog"
          cardInfo={getRelatedBlogPosts()}
          hubType={hubType}
          position="related posts cta"
          cta={{ buttonLabel: 'See more posts', type: 'secondary', url: `/${hubType}` }}
        />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default BlogDetailsTemplate;

export const BlogDetailsTemplateQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlog(seo: { pageUrl: { eq: $slug } }) {
      id
      updatedAt
      title
      seo {
        ...Seo
      }
      publishDate(formatString: "MMMM Do, YYYY")
      authorReference {
        ...Person
      }
      rawDate: publishDate
      featuredImage {
        ...Image
      }
      fullWidth
      inlineMarketingForm {
        ...InlineMarketingForm
      }
      summary {
        ...ConversionPanel
      }
      tags
      contentRichText {
        raw
        references {
          ...Ads
          ...Image
          ...BlockQuote
          ...CallToAction
          ...ConversionPanel
          ...EmbedCode
          ...WebsiteImage
          ...RowContent
          ...Table
          ...EmbeddedWistiaVideo
        }
      }
      relatedPosts {
        ...BlogSearchCard
      }
      tableOfContents
      sideBarCta {
        ...SideBar
      }
      updatedAt
    }
  }
`;

const BlogDetails = styled.div`
  padding: 60px 0 0 0;

  .section-blog-details--content {
    table {
      border-spacing: 0px;
      width: 100%;
      th,
      td {
        padding: 15px;
        border: 0.5px solid var(--grey-2);
        p {
          text-align: center;
        }
      }
    }
    .blog-details--email-subscribe-form,
    .blog-details--full-form {
      margin-top: 40px;
    }

    .blog-details--email-subscribe-form {
      .signup-form--form {
        padding-bottom: 0;
      }
    }

    @media (min-width: 576px) {
      th,
      td {
        width: max-content;
      }
    }
  }

  &.fullWidth {
    .section-blog-details--featured-image {
      width: 100%;
      margin: 0 auto;

      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .section-blog-details--content {
      width: 100%;
    }
  }

  &:not(.fullWidth) {
    .section-blog-details-contents {
      justify-content: normal;
      @media (min-width: 1200px) {
        width: calc(100% + 20px);
      }
    }
  }

  .signup-form--title {
    font-family: var(--font-family-heading);
    text-align: center;
  }
  .signup-form--form {
    padding: 33px 26px;
    background-color: white;

    .signup-form--footer {
      opacity: 1;
      width: 100%;
      font-size: var(--font-size-13);
      font-weight: var(--font-weight-500);
      line-height: var(--line-height-150);
      text-align: center;

      a {
        color: grey !important;
        text-decoration: underline;
      }

      p {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .section-blog-details-category {
    margin-bottom: 10px;
    h3 {
      font-size: var(--font-size-16);
      font-weight: var(--font-weight-600);
      line-height: var(--line-height-110);
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: var(--titan-blue-4);
      text-align: center;
    }
    @media (max-width: 991px) {
      justify-content: start;
    }
  }

  .section-blog-details-main-form-notification {
    text-align: center;
  }

  .section-blog-details-contents {
    margin: 0px auto !important;

    .section-blog-details-return-to-blog {
      a h4 {
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-700);
        line-height: var(--line-height-110);
        letter-spacing: var(--letter-spacing--0-01);
        color: var(--titan-blue-3);
      }
    }
    .section-blog-details--sidebar-contents {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .section-blog-details-sidebar {
      margin-left: -20px;
      margin-top: 60px;
      @media (max-width: 991px) {
        margin-left: 0px;
        margin-top: 50px;
      }
    }
    .section-blog-details-form-notification {
      max-width: 215px;
      margin: 0 auto 20px auto;
      text-align: center;
    }
  }

  .blog-details--full-form {
    .signup-form--title {
      font-family: var(--font-family-heading);
      color: var(--white);
    }
  }

  @media (max-width: 1536px) {
    .social-sharing-container {
      justify-content: center;
      margin: 20px 0 30px 0;

      @media (max-width: 992px) {
        img {
          margin: 0 10px;
        }
      }
      @media (max-width: 991px) {
        img {
          margin: 0 20px 0 0;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .blog-details--full-form {
      width: 100%;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    .section-blog-details-contents {
      flex-direction: row;
      .section-blog-details--main-contents {
        width: 100%;
      }

      .section-blog-details--sidebar-contents {
        width: 100%;
        padding-top: 30px;
        align-items: start;
      }
    }
    .social-sharing-container {
      justify-content: start;
      margin-bottom: 35px;
      gap: 0;
    }
    .section-blog-details-titles {
      justify-content: start;
    }
  }
`;

const BlogDetailsTitle = styled.h1`
  color: var(--darkest);
  margin-bottom: 20px;
  max-width: 984px;
  text-align: center;
  @media (max-width: 991px) {
    text-align: left;
  }
`;

const BlogAuthorDate = styled.div`
  width: 100%;
  margin: 0px auto 45px;
  img {
    margin-right: 8px;
    margin-bottom: 3px;
  }
  span {
    padding: 0 20px;
    text-transform: uppercase;
    font-size: var(--font-size-13);
    line-height: var(--line-height-150);
    letter-spacing: var(--letter-spacing--0-05);
    color: var(--grey-8);

    @media (max-width: 576px) {
      padding: 0 20px 0 0;
    }
  }
  @media (max-width: 1536px) {
    margin: 0px auto;
    span {
      padding: 0 20px 0 0;
    }
  }
  @media (max-width: 991px) {
    margin-bottom: 0;
  }
`;
