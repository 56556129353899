const getRelatedPosts = (taxonomy, posts) => {
  const relatedPosts = [];
  const excludedIndustries = ['All Industries', 'Other Industries'];
  const postIndustries = taxonomy?.industries;
  const industries = postIndustries?.filter((industry) => !excludedIndustries.includes(industry));

  const addPost = (post, type) => {
    if (relatedPosts.length < 3) {
      const postTaxonomy = post?.seo?.taxonomy?.[type];
      const currentTaxonomy = type === 'industries' ? industries : taxonomy?.[type];
      const includesTaxonomy = postTaxonomy?.some((tax) => currentTaxonomy?.includes(tax));

      if (includesTaxonomy) {
        relatedPosts.push(post);
      }
    }
  };

  if (industries?.length) {
    posts.forEach((post) => addPost(post, 'industries'));
  }

  posts.forEach((post) => addPost(post, 'topics'));

  return relatedPosts;
};

export default getRelatedPosts;
