import { graphql, useStaticQuery } from 'gatsby';

const getPosts = (posts) => posts.edges.map((edge) => edge.node);

const useCoronaPosts = () => {
  const { allContentfulBlog } = useStaticQuery(graphql`
    query {
      allContentfulBlog(
        filter: { tags: { eq: "Covid19" } }
        sort: { fields: [publishDate], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            __typename
            id
            title
            seo {
              ...Seo
            }
            excerpt {
              excerpt
            }
            featuredImage {
              ...Image
            }
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  `);
  return getPosts(allContentfulBlog);
};

export default useCoronaPosts;
